import React, { useEffect, useState } from 'react';
import Header from "./Header"
import MainMenuContent from './MainMenuContent';
import { Container, createTheme, CssBaseline, Divider, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { ThemeProvider } from '@emotion/react';

export const theme = createTheme({
  palette: {
    primary: {
      main: "#339999",
      light: "#6acaca",
      dark: "#006a6b"
    },
    secondary: {
      main: "#B73E42",
      light: "#C56467",
      dark: "#802B2E"
    }
  }
})

const Offset = styled('div')(({ theme }: any) => theme.mixins.toolbar);

function MainMenu() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <Offset />
        <Container maxWidth="lg" style={{marginTop: 20, minHeight: `calc(100vh - 158.5px)`, marginBottom: 20}}>
          <MainMenuContent />
        </Container>
        <Divider/>
        <div style={{display: 'flex', height: 50, alignItems: "center"}}>
          <Typography variant="caption" style={{flex: 1, textAlign: "center"}}>Copyright © Md Munawar Hussain, Last modified: 19 December 2021</Typography>
        </div>
      </ThemeProvider>
    </div>
  );
}
export default styled(MainMenu)`
  margin: 0px;
`
