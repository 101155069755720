
import styled from "@emotion/styled";
import { HorizontalRule, Work } from "@mui/icons-material";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem as MTimelineItem, TimelineSeparator } from "@mui/lab";
import { Divider, Grid, Link, Theme, Typography, useTheme } from "@mui/material";
import React, { Fragment } from "react";

let ATimelineItem = styled(MTimelineItem)`
    ::before{
        // flex: 0;
    }
`

let StyledTimeLineItem = styled(MTimelineItem)`
::before{
     flex: 0;
}
`

const getTimelineContent = (position: String, timeRange: String, content: any) => {
    return <div style={{ marginLeft: "20px" }}>
        <Typography variant="subtitle2">{position}</Typography>
        <Typography variant="caption" color="secondary">{timeRange}</Typography>
        <div style={{ marginBottom: 20, marginTop: 20 }}>
            {content}
        </div>
    </div>
}

const twiskerContent = (theme: Theme, TimelineItem:typeof StyledTimeLineItem) => <Fragment>
    <Grid container style={{ padding: "0px 12px" }} mt={5} columnSpacing={2}>
        <Grid item xs={12}>
            <Link href="https://www.linkedin.com/company/twisker/about/" underline="hover">
                <Typography variant="h5" color={theme.palette.primary.dark} align="center">
                    <img src="/images/twisker-logo.jpg" height={28} style={{ marginRight: 10 }} /> Twisker
                </Typography>
            </Link>
        </Grid>
    </Grid>
    <Timeline position="right">
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot></TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                {getTimelineContent("Software Engineer (Part time)", "July 2019 - August 2019", <Typography>
                    Implement library to generate diff view for rich text editor(Quill).<br />
                    <Typography variant="caption"><em>Stack: </em> ReactJs, nodeJs, mongoDB, graphQL</Typography>
                </Typography>)}
            </TimelineContent>
        </TimelineItem>
    </Timeline>
</Fragment>

const enosisContent = (theme: Theme, TimelineItem:typeof StyledTimeLineItem, position: "alternate"|"right") => <Fragment>
    <Grid container style={{ padding: "0px 12px" }} mt={5} columnSpacing={2}>
        <Grid item xs={12}>
            <Link href="https://www.enosisbd.com/" underline="hover">
                <Typography variant="h5" color={theme.palette.primary.dark} align="center">
                    <img src="/images/enosis-logo.png" width={74} /> Enosis Solutions
                </Typography>
            </Link>
        </Grid>
    </Grid>
    <Timeline position={position}>
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot></TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                {getTimelineContent("Senior Software Engineer (L-2)", "February 2021 - Present", <Typography>
                    Implementing solutions, performing R&D and adjusting system with latest libraries. <br />
                    Identify all <Link href="https://owasp.org/www-project-top-ten/" target="_blank">OWASP Top 10</Link> issues in application and fix severe vulnerabilities.<br />
                    Identify the possible optimizations in the application and implement database level optimization.<br />
                    Reviewing code changes and workflow of team members.<br />
                    <Typography variant="caption"><em>Stack: </em> Java 16, Spring 5, AngularJs, Oracle, SqlServer</Typography>
                </Typography>)}
            </TimelineContent>
        </TimelineItem>
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot></TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                {getTimelineContent("Senior Software Engineer", "February 2020 - January 2021", <Typography>
                    Implement solutions and perform R&D.<br />
                    Integrate several business modules in the application and integrate Gantt component to track the schedules in application.<br />
                    Review code changes and workflow of team members.<br />
                    <Typography variant="caption"><em>Stack: </em> Java 8, Spring 5, AngularJs, Oracle, SqlServer</Typography>
                </Typography>)}
            </TimelineContent>
        </TimelineItem>
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot></TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                {getTimelineContent("Software Engineer (L-2)", "February 2019 - January 2020", <Typography>
                    Implement solutions and perform R&D.<br />
                    Integrate SOAP consume service in the application.<br />
                    <Typography variant="caption"><em>Stack: </em> Java 8, Spring 5, AngularJs, Oracle, SqlServer</Typography>
                </Typography>)}
            </TimelineContent>
        </TimelineItem>
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot></TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                {getTimelineContent("Software Engineer", "February 2018 - January 2019", <Typography>
                    Implement solutions and perform R&D.<br />
                    Integrate jsPlumb community edition to connect modules and enhance the library to support some premium features.<br />
                    Design AWS API Gateway, lambdas and document them.<br />
                    <Typography variant="caption"><em>Stack: </em> ReactJs, Angular 4, nodeJs (AWS lambda), PostgresSQL</Typography>
                </Typography>)}
            </TimelineContent>
        </TimelineItem>
    </Timeline>
</Fragment>

export default function Experiences() {
    const theme = useTheme()
    return <Grid container columnSpacing={2}>
        <Grid item xs={12}>
            <Typography variant="h3"
                component="div">
                Experiences
            </Typography>
            <br />
            <Divider />
        </Grid>
        <Grid xs={12} sx={{display: {xs: 'block', md: 'none'}}}>
            {enosisContent(theme, StyledTimeLineItem, "right")}
            {twiskerContent(theme, StyledTimeLineItem)}
        </Grid>
        <Grid xs={12} sx={{display: {xs: 'none', md: 'block'}}}>
            {enosisContent(theme, ATimelineItem, "alternate")}
            {twiskerContent(theme, ATimelineItem)}
        </Grid>
    </Grid>
}