import { AppBar, Container, Toolbar, Typography, Box, IconButton, Menu, MenuItem, Button, Tooltip, Avatar, Link } from '@mui/material';
import { settings } from 'cluster';
import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { actions } from '../../store/actions';
import { useNavigate } from 'react-router-dom';
import { GitHub, LinkedIn } from '@mui/icons-material';

const LOGO = "Munawar Hussain";

const pages = [
  {
    label: "Experiences",
    path: "/experiences"
  },
  {
    label: "Projects",
    path: "/projects"
  },
  {
    label: "Misc.",
    path: "/misc"
  },
]

export default function Header() {
  const navigate = useNavigate()
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenuAnRedirect = (path: string) => {
    navigateTo(path)
    handleCloseNavMenu();
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigateToHome = () => navigate("");

  const navigateTo = (path: string) => navigate(path)

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{ mr: 10, display: { xs: 'none', md: 'flex' }, cursor: "pointer" }}
            onClick={navigateToHome}
          >
            {LOGO}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.label} onClick={() => handleCloseNavMenuAnRedirect(page.path)}>
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}

              <MenuItem onClick={handleCloseNavMenu}> <Link href="https://www.linkedin.com/in/md-munawar-hussain-shakil/" target='_blank' underline='hover'><Typography style={{display: "flex", alignItems: "center", color: 'rgba(0, 0, 0, 0.87)'}}><LinkedIn style={{ marginRight: 5 }} />Linkedin</Typography></Link></MenuItem>
              <MenuItem onClick={handleCloseNavMenu}> <Link href="https://github.com/ColorlessCoder" target='_blank' underline='hover'><Typography style={{display: "flex", alignItems: "center", color: 'rgba(0, 0, 0, 0.87)'}}><GitHub style={{ marginRight: 5 }} />Github</Typography></Link></MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, cursor: "pointer" }}
            onClick={navigateToHome}
          >
            {LOGO}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.label}
                onClick={() => handleCloseNavMenuAnRedirect(page.path)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.label}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 1, alignSelf: "center", flexDirection: "row-reverse", display: { xs: 'none', md: 'flex' } }}>
            <Button href="https://www.linkedin.com/in/md-munawar-hussain-shakil/" target='_blank' style={{ color: 'white', textTransform: "capitalize" }}><LinkedIn style={{ marginRight: 5 }} />Linkedin</Button>
            <Button href="https://github.com/ColorlessCoder" target='_blank' style={{ color: 'white', textTransform: "capitalize" }}><GitHub style={{ marginRight: 5 }} />Github</Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
