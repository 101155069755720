
import styled from "@emotion/styled";
import { Android, Extension, Work } from "@mui/icons-material";
import { Card, CardContent, Divider, Fab, FormControlLabel, Grid, Link, SvgIcon, Switch, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";

const getFab = (icon: any) => <Fab disabled style={{ marginBottom: 20, position: "absolute", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: 'white', boxShadow: '1px 1px  rgb(0 0 0 / 12%), -1px -1px  rgb(0 0 0 / 12%), 1px -1px  rgb(0 0 0 / 12%), -1px 1px  rgb(0 0 0 / 12%)' }}>
    {icon}
</Fab>

const JiraTimeTracker = <Card variant="outlined" style={{ marginTop: 10 }}>
    {getFab(<Extension htmlColor="black" />)}
    <CardContent style={{marginTop: 20}}><Grid container rowSpacing={2}>
        <Grid item xs={12}>
            <Link href="https://github.com/ColorlessCoder/jira-extension" underline="hover" target="_blank">
                <Typography variant="h5">Jira Time Tracker</Typography>
            </Link>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="caption">Personal Project (January 2021 - Present)</Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography> A chrome extension to track daily time in jira accurately. Logs can be created at the start of an issue as a resumed state like a timer, and once the issue completed, the time can be paused and upload to jira (or upload it later). That way no manual calculation or assumption is needed. There is also a feature to create note while working on a issue.</Typography>
        </Grid>
        {/* <Grid item xs={12}>
            Behind the scene: After entering the era of "working from Home", I had to spent a large amount of time in communication. After one call, I had to join another one.
            Because of engaging in continuous calls and due to use online meeting url, it was difficult for me to track how much time I spent on which call. And also it is hard to calculate how much time I spent in my own development.
        </Grid> */}
        <Grid item xs={12}>
            <Typography variant="subtitle2">Stack: ReactJs</Typography>
        </Grid>
    </Grid>
    </CardContent>
</Card>

const Organizer = <Card variant="outlined" style={{ marginTop: 10 }}>
    {getFab(<Android htmlColor="#3ddc84" />)}
    <CardContent style={{marginTop: 20}}><Grid container rowSpacing={2}>
        <Grid item xs={12}>
            <Link href="https://github.com/ColorlessCoder/Organizer" underline="hover" target="_blank">
                <Typography variant="h5">Organizer</Typography>
            </Link>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="caption">Personal Project (August 2020 - Present)</Typography>
        </Grid>
        <Grid item xs={12}>
            An android app to organize daily lifestyle. Currently, the application only has one module (and will continue to integrate new modules) that is Money manager which can handle categorize expense and saving at the beginning, tracking debts and transactions.<br />
        </Grid>
        <Grid item xs={12}>
            <Typography variant="subtitle2">Stack: kotlin, SQLite</Typography>
        </Grid>
    </Grid>
    </CardContent>
</Card>

const parquet = <Card variant="outlined" style={{ marginTop: 10 }}>
    {getFab(<img src="images/java-4-logo-svgrepo-com.svg" style={{width: "60%"}}/>)}
    <CardContent style={{marginTop: 20}}><Grid container rowSpacing={2}>
        <Grid item xs={12}>
            <Link href="https://github.com/ColorlessCoder/csv-to-parquet-converter-java" underline="hover" target="_blank">
                <Typography variant="h5">CSV to Parquet Convertor</Typography>
            </Link>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="caption">Personal Project (April 2021)</Typography>
        </Grid>
        <Grid item xs={12}>
            A java desktop application to convert csv file to <Link href="https://parquet.apache.org/documentation/latest/">parquet</Link>. The application can be run in Java 6 or higher environment. The advantage of parquet is that, it can store the same information of CSV file but in compressed manner and will take less space.
        </Grid>
        <Grid item xs={12}>
            <Typography variant="subtitle2">Stack: Java</Typography>
        </Grid>
    </Grid>
    </CardContent>
</Card>

const Enosis1 = <Card variant="outlined" style={{ marginTop: 10 }}>
    {getFab(<Work color="primary" />)}
    <CardContent style={{marginTop: 20}}>
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5"> Real-time Crime and News Data Sharing Portal</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption">Company: Enosis Solutions (February 2018 - July 2018)</Typography>
            </Grid>
            <Grid item xs={12}>
                A portal of viewing the crimes occurred in a area with categories. The application also notifies subscribed users and let all users share/contribute details to the crime. <br />
                My contributions
            </Grid>
            <Grid xs={1}></Grid>
            <Grid xs={11}>
                - Wrote AWS lambdas functions to view and manipulate crime/news data<br />
                - Developed view components in the portal (using React.js)<br />
                - Integrate functionality of sharing videos in the portal.
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2">Stack: ReactJs, NodeJs, PostgresSQL</Typography>
            </Grid>
        </Grid>
    </CardContent>
</Card>

const Enosis2 = <Card variant="outlined" style={{ marginTop: 10 }}>
    {getFab(<Work color="primary" />)}
    <CardContent style={{marginTop: 20}}>
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">AWS Architecture Builder</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption">Company: Enosis Solutions (February 2018 - July 2018)</Typography>
            </Grid>
            <Grid item xs={12}>
                A web application for help user building a structure connecting AWS components before actually create components in AWS. After finalizing the structure the application let user download the structure as terraform or upload it to a repository.<br />
                My contributions
            </Grid>
            <Grid xs={1}></Grid>
            <Grid xs={11}>
                - Wrote Canvas from scratch to create component, validate and connect using jsPlumb<br />
                - Wrote AWS lambdas with nodeJs to store the architecture and settings<br />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2">Stack: Angular 4, NodeJs, PostgresSQL</Typography>
            </Grid>
        </Grid>
    </CardContent>
</Card>

const Enosis3 = <Card variant="outlined" style={{ marginTop: 10 }}>
    {getFab(<Work color="primary" />)}
    <CardContent style={{marginTop: 20}}>
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5"> QMS Quality Management Solution and Shop Floor Execution</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption">Company: Enosis Solutions (September 2018 - Present)</Typography>
            </Grid>
            <Grid item xs={12}>
                A web application to efficiently manage daily production processes on the shop floor and automate quality compliance with a single QMS solution. <br />
                My contributions
            </Grid>
            <Grid xs={1}></Grid>
            <Grid xs={11}>
                - Contributed to 500+ features and help the team with technical difficulties and business logic.<br />
                - Integrate SOAP and Active Directory consume service to communicate with other interfaces<br />
                - Identify the web security issues and provide solutions<br />
                - Upgraded 30+ libraries and project environments to resolve possible vulnerabilities and adjust to modern versions<br />
                - Review code changes of 4 team members and contribute in product design to improve the quality<br />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2">Stack: Java, Spring, angularJS, Oracle, Sql Server</Typography>
            </Grid>
        </Grid>
    </CardContent>
</Card>

const Twisker = <Card variant="outlined" style={{ marginTop: 10 }}>
    {getFab(<Work color="primary" />)}
    <CardContent style={{marginTop: 20}}>
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">Document Management with Version Controlling</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption">Company: Twisker (July 2019 - August 2019)</Typography>
            </Grid>
            <Grid item xs={12}>
                An online web platform to manage personal or business documents with version control feature.
            </Grid>
            <Grid xs={1}></Grid>
            <Grid xs={11}>
                - Integrate functionality to upload non-viewable file in document editor<br/>
                - Implement diff view for version controlling
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2">Stack: ReactJs, NodeJs, MongoDB, graphQL</Typography>
            </Grid>
        </Grid>
    </CardContent>
</Card>


export default function Projects() {
    const [personal, setPersonal] = useState(true);
    const [work, setWork] = useState(true);
    return <div style={{ marginBottom: 10 }}>
        <Grid container style={{alignItems: "center"}}>
            <Grid xs={6}>
                <Typography variant="h3">Projects</Typography>
            </Grid>
            <Grid xs={3}>
                <FormControlLabel control={<Switch checked={personal} onClick={() => setPersonal(!personal)}/>} label="Personal Projects" />
            </Grid>
            <Grid xs={3}>
                <FormControlLabel control={<Switch checked={work} onClick={() => setWork(!work)}/>} label="Work Projects" />
            </Grid>
        </Grid>
        <br />
        {/* <Divider /> */}
        <Grid container columnSpacing={5} rowSpacing={5} mt={2}>
            {personal && <Grid item xs={12}>{JiraTimeTracker}</Grid>}
            {personal && <Grid item xs={12}>{Organizer}</Grid>}
            {personal && <Grid item xs={12}>{parquet}</Grid>}
            {work && <Grid item xs={12}>{Enosis1}</Grid>}
            {work && <Grid item xs={12}>{Enosis2}</Grid>}
            {work && <Grid item xs={12}>{Enosis3}</Grid>}
            {work && <Grid item xs={12}>{Twisker}</Grid>}
        </Grid>
    </div>
}