
import styled from "@emotion/styled";
import { Email, EmailOutlined } from "@mui/icons-material";
import { Chip, Divider, Grid, LinearProgress, Link, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

const myDetails = <Fragment>
    <Typography variant="h6" color="primary">
            Md Munawar Hussain
        </Typography>
        <Typography variant="caption" component="div">
            Senior Software Engineer
        </Typography>
        <Typography variant="caption" component="div">
            Enosis Solutions
        </Typography>
        <Typography variant="caption" component="div" style={{ display: 'flex', alignItems: "center" }}>
            <EmailOutlined fontSize="small" color="secondary" /> <Link color="secondary" ml={1} href="mailto:shakil.ruet.12@gmail.com" underline="hover">shakil.ruet.12@gmail.com</Link>
        </Typography>
</Fragment>

const header = <Grid container columnSpacing={4}>
    <Grid item xs={1} minWidth={340}>
        <div style={{ height: 302, width: 302, border: "1px solid rgba(0,0,0,.28)", padding: 5 }}>
            <img src="images/profile_pic.jpg" height="290px" />
        </div>
    </Grid>
    <Grid item xs={5} alignSelf="end" sx={{display: {xs: 'none', md: 'block'}}}>
        {myDetails}
    </Grid>
    <Grid item xs={12} alignSelf="end" sx={{display: {xs: 'block', md: 'none'}}}>
        {myDetails}
    </Grid>
</Grid>

const summary = <Fragment>
    <Typography variant="subtitle1">
                <br />
                I am working as a Senior Software Engineer at <Link target="_blank" underline="hover" href="https://www.enosisbd.com/">Enosis Solutions</Link>. I am experienced in developing high quality and reliable solutions. Cutting edge development and problem solving are my top interest. Programmer by passion and profession. Never miss a chance to thinking out of the box to find efficient and secure solution. Proficient in English and Agile/Scrum methodology. Love to develop <Link target="_blank" underline="hover" component={NavLink} to="/projects">personal applications</Link> to resolve my necessities and daily struggles. Often create generators or console apps to fast my developments.
            </Typography>
            <Typography variant="subtitle1">
                <br />
                I completed my Bachelors in Computer Science and Engineering in 2017 from <Link target="_blank" underline="hover" href="https://www.cse.ruet.ac.bd/">Rajshahi University of Engineering and Technology (RUET)</Link>, Rajshahi, Bangladesh. While doing my Bachelors, I participated in several <Link target="_blank" underline="hover" component={NavLink} to="/misc#contest">onsite programming contests</Link> including <Link target="_blank" underline="hover" href="https://icpc.global/">ICPC</Link>.
                <br />
                <br />
            </Typography>
            <Typography variant="subtitle2">
                Interests:
                <Chip style={{ marginBottom: 5, marginLeft: 10 }} variant="outlined" color="info" clickable={false} label="Software Engineering" /> <Chip style={{ marginBottom: 5 }} variant="outlined" color="info" clickable={false} label="Machine Learning" /> <Chip style={{ marginBottom: 5 }} variant="outlined" color="info" clickable={false} label="Computer Vision" />
            </Typography>
</Fragment>

export default function Home() {
    return <Grid container columnSpacing={2} justifyContent="center" justifyItems="center">
        <Grid item xs={10}>
            {header}
        </Grid>
        <Grid item xs={10}>
            {summary}
        </Grid>
        <Grid item xs={10} mt={5}>
            <Typography variant="h4" mb={1} color="primary">Skills</Typography>
            <Divider/>
            <Grid container columnSpacing={5}>
                <Grid item xs={6}>
                    <Grid container mt={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Java</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress color="warning" variant="determinate" value={100}/>
                        </Grid>
                        <Grid item xs={12} textAlign="right">
                            <Typography variant="subtitle2">Excellent</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container mt={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Analytical Skills</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress color="info" variant="determinate" value={100}/>
                        </Grid>
                        <Grid item xs={12} textAlign="right">
                            <Typography variant="subtitle2">Excellent</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container mt={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">AngularJs</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress color="secondary" variant="determinate" value={100}/>
                        </Grid>
                        <Grid item xs={12} textAlign="right">
                            <Typography variant="subtitle2">Excellent</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container mt={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">HTML/CSS</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress color="success" variant="determinate" value={100}/>
                        </Grid>
                        <Grid item xs={12} textAlign="right">
                            <Typography variant="subtitle2">Excellent</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container mt={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">ReactJs</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress color="info" variant="determinate" value={80}/>
                        </Grid>
                        <Grid item xs={12} textAlign="right">
                            <Typography variant="subtitle2">Very Good</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container mt={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Spring</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress color="warning" variant="determinate" value={80}/>
                        </Grid>
                        <Grid item xs={12} textAlign="right">
                            <Typography variant="subtitle2">Very Good</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container mt={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">C++</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress color="success" variant="determinate" value={80}/>
                        </Grid>
                        <Grid item xs={12} textAlign="right">
                            <Typography variant="subtitle2">Very Good</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}