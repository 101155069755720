
import styled from "@emotion/styled";
import { Circle } from "@mui/icons-material";
import { Divider, Grid, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";

const Awards = <div>
    <Typography variant="h4">Awards</Typography>
    <Divider />
    <Grid container style={{ paddingLeft: 30, marginTop: 10 }} rowSpacing={2}>
        <Grid item xs={12}>
            <Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }}> <Circle fontSize="small" style={{ height: 10, marginLeft: -30, marginRight: 10 }} />Award of Excellence - 2016</Typography>
            <Typography variant="caption" component="div">Programming Contest</Typography>
            <Typography variant="caption" component="div">Dept. of Computer Science and Engineering</Typography>
            <Typography variant="caption" component="div">Rajshahi University of Engineering and Technology (RUET)</Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }}> <Circle fontSize="small" style={{ height: 10, marginLeft: -30, marginRight: 10 }} />Divisional Champion</Typography>
            <Typography variant="caption" component="div">ACM ICPC Dhaka Regional - 2016</Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }}> <Circle fontSize="small" style={{ height: 10, marginLeft: -30, marginRight: 10 }} />Divisional Champion</Typography>
            <Typography variant="caption" component="div">CUET National Collegiate Programming Contest - 2017</Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }}> <Circle fontSize="small" style={{ height: 10, marginLeft: -30, marginRight: 10 }} />Champion</Typography>
            <Typography variant="caption" component="div">VU CSE Carnival 2017 Programming Contest</Typography>
        </Grid>
    </Grid>
</div>

const ProgrammingContest = <div>
    <a href="#contest"/>
    <Typography variant="h4">Programming Contests</Typography>
    <TableContainer component={Paper} style={{marginTop: 20}}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
                <TableRow>
                    <TableCell>Year</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Position</TableCell>
                    <TableCell align="center">Team</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">2017</TableCell>
                    <TableCell align="left">VU CSE Carnival 2017 Programming Contest</TableCell>
                    <TableCell align="left">1<sup>st</sup></TableCell>
                    <TableCell align="center">RUET_Thriller_Bark</TableCell>
                </TableRow>
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">2017</TableCell>
                    <TableCell align="left">ACM ICPC ASIA Dhaka Regional Contest </TableCell>
                    <TableCell align="left">12<sup>th</sup></TableCell>
                    <TableCell align="center">RUET_Thriller_Bark</TableCell>
                </TableRow>
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">2017</TableCell>
                    <TableCell align="left">IUT 9th ICT Fest 2017 Programming Contest</TableCell>
                    <TableCell align="left">13<sup>th</sup></TableCell>
                    <TableCell align="center">RUET_Thriller_Bark</TableCell>
                </TableRow>
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">2017</TableCell>
                    <TableCell align="left">CUET NCPC </TableCell>
                    <TableCell align="left">21<sup>st</sup>, Divisional Champion</TableCell>
                    <TableCell align="center">RUET_Final_Laughter</TableCell>
                </TableRow>
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">2016</TableCell>
                    <TableCell align="left">ACM ICPC ASIA Dhaka Regional Contest </TableCell>
                    <TableCell align="left">22<sup>th</sup>, Divisional Champion, Top 10 University</TableCell>
                    <TableCell align="center">RUET_OSIRIS</TableCell>
                </TableRow>
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">2016</TableCell>
                    <TableCell align="left">AB Bank IUT 8th ICT Fest Programming Contest</TableCell>
                    <TableCell align="left">20<sup>th</sup></TableCell>
                    <TableCell align="center">RUET_OSIRIS</TableCell>
                </TableRow>
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">2016</TableCell>
                    <TableCell align="left">Cybernauts'16 National Programming Contest</TableCell>
                    <TableCell align="left">17<sup>th</sup></TableCell>
                    <TableCell align="center">RUET_Gallinule</TableCell>
                </TableRow>
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">2015</TableCell>
                    <TableCell align="left">RUET IEEE Intra University Programming Contest </TableCell>
                    <TableCell align="left">2<sup>nd</sup></TableCell>
                    <TableCell align="center">RUET_OSIRIS</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>
</div>


export default function Misc() {
    return <Grid container rowSpacing={10}>
        <Grid item xs={12}>{Awards}</Grid>
        <Grid item xs={12}>{ProgrammingContest}</Grid>
    </Grid>

}