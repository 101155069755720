import { Route, Routes } from "react-router-dom";
import React from "react";
import Home from "../Home";
import Projects from "../Projects";
import Experiences from "../Experiences";
import Misc from "../Misc";
export default function MainMenuContent() {
    return (
        <Routes>
            <Route index element={<Home />}/>
            <Route path="/projects" element={<Projects />}/>
            <Route path="/experiences" element={<Experiences />}/>
            <Route path="/misc" element={<Misc />}/>
        </Routes>
    );
}