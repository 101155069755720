import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import mainMenuSlice from "./slices/mainMenuSlice";

const store = configureStore({
  reducer: {
    mainMenu: mainMenuSlice,
  },
  middleware: getDefaultMiddleware()
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;